import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo 
      title="404: Not found" 
      description="Page cannot be found."
    />

    <section className="bg--black">
      <div className="container">
        <div className="text-center flow">
          <h1>404: Not Found</h1>
          <p className="text--white">We're sorry. The page you requested cannot be found.</p>
          <a href="/" className="btn">Go home</a>
        </div>
      </div>  
    </section>
    
  </Layout>
)

export default NotFoundPage
